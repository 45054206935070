<template>
  <div id="waterQualityRecord">
    <el-dialog
      :title="waterQualityRecordFormTitle"
      width="1200px"
      :visible.sync="waterQualityRecordDialogVisible"
      :close-on-click-modal="false"
      @close="waterQualityRecordDialogClose"
    >
      <el-form
        ref="waterQualityRecordFormRef"
        :model="waterQualityRecordForm"
        :rules="waterQualityRecordFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="工艺用水类别" prop="waterCategory">
              <el-select v-model="waterQualityRecordForm.waterCategory" placeholder="请输入工艺用水类别">
                 <el-option key="1" label="饮用水" :value="1" />
                 <el-option key="2" label="纯化水" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测日期" prop="checkDate">
              <el-date-picker v-model="waterQualityRecordForm.checkDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="取样点" prop="samplePoint">
              <el-input v-model="waterQualityRecordForm.samplePoint" placeholder="请输入取样点" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测项目" prop="testItems">
              <el-input v-model="waterQualityRecordForm.testItems" placeholder="请输入检测项目" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测次数" prop="testNumber">
              <el-input v-model="waterQualityRecordForm.testNumber" placeholder="请输入检测次数" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="酸碱度" prop="ph">
              <el-input v-model="waterQualityRecordForm.ph" placeholder="请输入酸碱度" clearable />
            </el-form-item>
          </el-col>
          
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="电导率" prop="conductivity">
              <el-input v-model="waterQualityRecordForm.conductivity" placeholder="请输入电导率" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="肉眼可见物" prop="visible">
              <el-input v-model="waterQualityRecordForm.visible" placeholder="请输入肉眼可见物" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测结果" prop="testResults">
              <el-select v-model="waterQualityRecordForm.testResults" placeholder="请输入检测结果">
                 <el-option key="1" label="合格" :value="1" />
                 <el-option key="2" label="不合格" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测人" prop="inspector">
              <el-input v-model="waterQualityRecordForm.inspector" placeholder="请输入检测人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测时间" prop="detectionTime">
              <el-date-picker v-model="waterQualityRecordForm.detectionTime" placeholder="请选择日期" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="waterQualityRecordForm.remarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="5"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="waterQualityRecordDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="waterQualityRecordFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设备编号">
        <el-input v-model="searchForm.deviceNo" placeholder="请输入设备编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="waterQualityRecordPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="waterCategory" label="工艺用水类别" >
        <template slot-scope="scope">
          <span v-if="scope.row.waterCategory == 1">饮用水</span>
          <span v-if="scope.row.waterCategory == 2">纯化水</span>
        </template>
      </el-table-column>
      <el-table-column prop="checkDate" label="检测日期" />
      <el-table-column prop="samplePoint" label="取样点" />
      <el-table-column prop="testItems" label="检测项目" />
      <el-table-column prop="testNumber" label="检测次数" />
      <el-table-column prop="ph" label="酸碱度" />
      <el-table-column prop="conductivity" label="电导率" />
      <el-table-column prop="visible" label="肉眼可见物" />
      <el-table-column prop="testResults" label="检测结果" >
        <template slot-scope="scope">
          <span v-if="scope.row.testResults == 1">合格</span>
          <span v-if="scope.row.testResults == 2">不合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="inspector" label="检测人" />
      <el-table-column prop="detectionTime" label="检测时间" />
      <el-table-column prop="remarks" label="备 注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="waterQualityRecordPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addWaterQualityRecord, deleteWaterQualityRecord, updateWaterQualityRecord, selectWaterQualityRecordInfo, selectWaterQualityRecordList } from '@/api/quality/waterQualityRecord'

export default {
  data () {
    return {
      waterQualityRecordDialogVisible: false,
      waterQualityRecordFormTitle: '',
      waterQualityRecordForm: {
        id: '',
        waterCategory: '',
        checkDate: '',
        samplePoint: '',
        testItems: '',
        testNumber: '',
        ph: '',
        conductivity: '',
        visible: '',
        testResults: '',
        inspector: '',
        remarks: '',
        detectionTime: ''
      },
      waterQualityRecordFormRules: {
        samplePoint: [{ required: true, message: '取样点不能为空', trigger: ['blur', 'change']}]
      },
      waterQualityRecordPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceNo: ''
      }
    }
  },
  created () {
    selectWaterQualityRecordList(this.searchForm).then(res => {
      this.waterQualityRecordPage = res
    })
  },
  methods: {
    waterQualityRecordDialogClose () {
      this.$refs.waterQualityRecordFormRef.resetFields()
      this.waterQualityRecordForm.id = ''
    },
    waterQualityRecordFormSubmit () {
      if (this.waterQualityRecordFormTitle === '工艺用水水质日常检测') {
        this.waterQualityRecordDialogVisible = false
        return
      }
      this.$refs.waterQualityRecordFormRef.validate(async valid => {
        if (valid) {
          if (this.waterQualityRecordFormTitle === '新增工艺用水水质日常检测') {
            await addWaterQualityRecord(this.waterQualityRecordForm)
          } else if (this.waterQualityRecordFormTitle === '修改工艺用水水质日常检测') {
            await updateWaterQualityRecord(this.waterQualityRecordForm)
          }
          this.waterQualityRecordPage = await selectWaterQualityRecordList(this.searchForm)
          this.waterQualityRecordDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.waterQualityRecordFormTitle = '新增工艺用水水质日常检测'
      this.waterQualityRecordDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteWaterQualityRecord(row.id)
        if (this.waterQualityRecordPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.waterQualityRecordPage = await selectWaterQualityRecordList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.waterQualityRecordFormTitle = '修改工艺用水水质日常检测'
      this.waterQualityRecordDialogVisible = true
      this.selectWaterQualityRecordById(row.id)
    },
    handleInfo (index, row) {
      this.waterQualityRecordFormTitle = '工艺用水水质日常检测详情'
      this.waterQualityRecordDialogVisible = true
      this.selectWaterQualityRecordById(row.id)
    },
    selectWaterQualityRecordById (id) {
      selectWaterQualityRecordInfo(id).then(res => {
        this.waterQualityRecordForm.id = res.id
        this.waterQualityRecordForm.waterCategory = res.waterCategory
        this.waterQualityRecordForm.checkDate = res.checkDate
        this.waterQualityRecordForm.samplePoint = res.samplePoint
        this.waterQualityRecordForm.testItems = res.testItems
        this.waterQualityRecordForm.testNumber = res.testNumber
        this.waterQualityRecordForm.ph = res.ph
        this.waterQualityRecordForm.conductivity = res.conductivity
        this.waterQualityRecordForm.visible = res.visible
        this.waterQualityRecordForm.testResults = res.testResults
        this.waterQualityRecordForm.inspector = res.inspector
        this.waterQualityRecordForm.detectionTime = res.detectionTime
         this.waterQualityRecordForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectWaterQualityRecordList(this.searchForm).then(res => {
        this.waterQualityRecordPage= res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectWaterQualityRecordList(this.searchForm).then(res => {
        this.waterQualityRecordPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectWaterQualityRecordList(this.searchForm).then(res => {
        this.waterQualityRecordPage = res
      })
    }
  }
}
</script>

<style>
</style>
