import axios from '@/common/axios'
import qs from 'qs'

export function addWaterQualityRecord (data) {
  return axios({
    method: 'post',
    url: '/quality/water/add',
    data: qs.stringify(data)
  })
}

export function deleteWaterQualityRecord (id) {
  return axios({
    method: 'delete',
    url: '/quality/water/delete/' + id
  })
}

export function updateWaterQualityRecord (data) {
  return axios({
    method: 'put',
    url: '/quality/water/update',
    data: qs.stringify(data)
  })
}

export function selectWaterQualityRecordInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/water/info/' + id
  })
}

export function selectWaterQualityRecordList (query) {
  return axios({
    method: 'get',
    url: '/quality/water/list',
    params: query
  })
}
